.card {
    --card-gradient: rgba(0, 0, 0, 0.8);
  
    --card-blend-mode: overlay;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0.05rem 0.1rem 0.3rem -0.03rem rgba(0, 0, 0, 0.45);
    padding-bottom: 1rem;
    background-image: linear-gradient(var(--card-gradient), white max(9.5rem, 27vh));
    overflow: hidden;
  }
  .card img {
    border-radius: 0.5rem 0.5rem 0 0;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    max-height: max(10rem, 30vh);
    aspect-ratio: 4/3;
    mix-blend-mode: var(--card-blend-mode);
  }
  .card img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .card > :last-child {
    margin-bottom: 0;
  }
  .card:hover, .card:focus-within {
    --card-gradient: #d5c324 max(8.5rem, 20vh);
  }
  
  /* Additional demo display styles */
  * {
    box-sizing: border-box;
  }
  
 
  .card h3 {
    margin-top: 1rem;
    font-size: 1.25rem;
    text-transform: capitalize;
  }
  
  .card a {
    color: inherit;
    text-transform: capitalize;
  }
  
  .card-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
    grid-gap: 1.5rem;
    max-width: 100vw;
    width: 120ch;
    padding-left: 1rem;
    padding-right: 1rem;
  }