body {
	background: linear-gradient(-45deg, #ee7752, #2c2929, #b8c04e, #e7968b);
	background-size: 4000% 4000%;
	animation: gradient 10s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.grid-container {
    width: 80%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
}
.header {
	text-align: center;
	color: white;
	font-size: 20px;
  }
  
  
  button {
    border: 4px solid black;
    padding: 16px 24px;
    margin: 10px;
    position: relative;
    color: beige;
    background-color: transparent;
  }
  button span {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    letter-spacing: 5px;
  }
  button:after {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 100%;
    width: 100%;
    background-color: #0011ff;
    content: "";
    z-index: -1;
    transition: transform 0.2s ease-in, width 0.2s ease-out 0.2s;
  }
  button:hover:after {
    transform: translate(-10px, -10px);
    width: calc(100% - 10px);
  }
  button:active:after {
    width: 100%;
  }
 